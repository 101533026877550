
html {
  -webkit-box-sizing: border-box;
       box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
      box-sizing: inherit;
}

html,body,#root {
  height: 100%;
  width: 100%;
  overflow: hidden
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d0d0d0; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiSelect-icon{
  position: relative !important;
}

.zoomable-image {
  cursor: grab;
}

.zoomable-image:active {
  cursor: grabbing;
}
svg > g{
  padding-left: 2em;
}

.MuiTablePagination-input{
  background-color: white !important;
}

.MuiChartsAxis-tickLabel {
  fill: #808080B0 !important;
  font-size: 10px !important
  
}
.MuiBarLabel-root {
  fill: white !important;
}

.MuiChartsAxis-line{
  stroke: #808080B0 !important;

}
